
























































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import JwlToggle from './jwlToggle.vue';

const IconDownload = () => import('common-modules/src/assets/fa-icons/solid/download.svg');
const IconFile = () => import('common-modules/src/assets/fa-icons/duotone/file.svg');
const IconFileAlt = () => import('common-modules/src/assets/fa-icons/duotone/file-alt.svg');
const IconFileArchive = () => import('common-modules/src/assets/fa-icons/duotone/file-archive.svg');
const IconFileAudio = () => import('common-modules/src/assets/fa-icons/duotone/file-audio.svg');
const IconFileExcel = () => import('common-modules/src/assets/fa-icons/duotone/file-excel.svg');
const IconFileImage = () => import('common-modules/src/assets/fa-icons/duotone/file-image.svg');
const IconFilePdf = () => import('common-modules/src/assets/fa-icons/duotone/file-pdf.svg');
const IconFilePowerpoint = () => import('common-modules/src/assets/fa-icons/duotone/file-powerpoint.svg');
const IconFileVideo = () => import('common-modules/src/assets/fa-icons/duotone/file-video.svg');
const IconFileWord = () => import('common-modules/src/assets/fa-icons/duotone/file-word.svg');
const IconFolder = () => import('common-modules/src/assets/fa-icons/duotone/folder.svg');
const IconFolderOpen = () => import('common-modules/src/assets/fa-icons/duotone/folder-open.svg');

export interface CourseDocument {
  id: number;
  type: 'file' | 'folder' | string;
  url: string,
  name: string,
  size: string,
  mimeType: string,
  children?: CourseDocument[];
}

@Component({
  name: 'lms-course-document',
  components: {
    IconDownload,
    IconFile,
    IconFileAlt,
    IconFileArchive,
    IconFileAudio,
    IconFileExcel,
    IconFileImage,
    IconFilePdf,
    IconFilePowerpoint,
    IconFileVideo,
    IconFileWord,
    IconFolder,
    IconFolderOpen,
    JwlToggle,
  },
})
export default class LmsCourseDocument extends Vue {
  @Prop([Object, Array])
  jwlFile!: CourseDocument | CourseDocument[];

  open = false;

  toggleOpen (): void {
    this.open = !this.open;
  }

  getIcon (file: CourseDocument): string {
    switch (file.mimeType) {
      case 'application/pdf':
        return 'icon-file-pdf';
      case 'text/plain':
        return 'icon-file-alt';
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/vnd.oasis.opendocument.text':
        return 'icon-file-word';
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'application/vnd.oasis.opendocument.spreadsheet':
        return 'icon-file-excel';
      case 'application/vnd.ms-powerpoint':
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      case 'application/vnd.oasis.opendocument.presentation':
        return 'icon-file-powerpoint';
      case 'video/mpeg':
      case 'video/mp4':
      case 'video/ogg':
      case 'video/mp2t':
      case 'video/x-msvideo':
      case 'video/3gpp':
      case 'video/3gpp2':
        return 'icon-file-video';
      case 'audio/aac':
      case 'audio/midi':
      case 'audio/x-midi':
      case 'audio/mpeg':
      case 'audio/ogg':
      case 'audio/opus':
      case 'audio/wav':
      case 'audio/webm':
      case 'audio/3gpp':
      case 'audio/3gpp2':
        return 'icon-file-audio';
      case 'image/gif':
      case 'image/bmp':
      case 'image/jpeg':
      case 'image/png':
      case 'image/svg+xml':
      case 'image/webp':
        return 'icon-file-image';
      case 'application/zip':
      case 'application/x-7z-compressed':
        return 'icon-file-archive';
      default:
        return 'icon-file';
    }
  }
}
