







import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class JwlToggle extends Vue {
  @Prop(Boolean)
  isOpen!: boolean;

  get openClass (): Record<string, boolean> {
    return {
      'jwl-toggle--open': this.isOpen,
    };
  }
}
