













import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Route } from 'vue-router';
import CommonIcon from 'common-modules/src/components/CommonIcon.vue';

@Component({
  components: {
    CommonIcon,
  },
})
export default class BackBar extends Vue {
  @Prop(Object)
  toRoute!: Route;

  @Prop(Boolean)
  isSticky!: boolean;

  @Prop(Boolean)
  isBottom!: boolean;

  get rootClasses (): Record<string, boolean> {
    return {
      'back-bar--sticky': this.isSticky,
      'back-bar--bottom': this.isBottom,
    };
  }
}
