








































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Task } from 'common-modules/src/store/interface/Task';
import { ExtendedRunTask, RunTask } from '@/store/interface/Task';

const IconClock = () => import('common-modules/src/assets/fa-icons/duotone/clock.svg');

@Component({
  components: {
    IconClock,
  },
})
export default class JwlTaskMeta extends Vue {
  @Prop(Object)
  jwlTask!: Task | RunTask | ExtendedRunTask;

  @Prop(Boolean)
  reducedMobile!: boolean;

  get submitByDate (): string | null {
    if (!('studentDeadline' in this.jwlTask) || !this.jwlTask.studentDeadline) {
      return null;
    }

    const date = new Date(this.jwlTask.studentDeadline);
    return date.toLocaleDateString(document.documentElement.lang, { timeZone: 'UTC' });
  }

  get rootClass (): string {
    return this.reducedMobile
      ? 'jwl-task-meta--reduced-mobile'
      : '';
  }
}
