<template lang="pug">
  .container
    back-bar(:to-route="{ name: 'unit_overview' }")
      | {{$t('gradebook.backToUnit')}}

    common-error(:jwl-error="error" v-if="error && submitStatus === 0")

    .jwl-submit-task(v-if="loaded" :class="gradeThemeClass")
      .jwl-submit-task__grade-container
        .jwl-submit-task__headline
          .jwl-submit-task__headline-text
            .jwl-submit-task__headline-icon
              icon-file-alt-duotone(v-if="work.id")
              icon-file-alt-regular(v-else)
            | {{$t('task.type.submit_text')}}

          .jwl-submit-task__code
            | {{task.code}}

        .jwl-submit-task__description-container
          .jwl-submit-task__description-headline
            | {{$t('gradebook.originalTask')}}

          jwl-task-meta(:jwl-task="task" :reduced-mobile="false")

          .jwl-submit-task__description
            .jwl-submit-task__description-title
              | {{task.title}}
            .jwl-submit-task__description-text(v-html="task.description")

          .jwl-submit-task__documents(v-if="task.facilitatorResources && task.facilitatorResources.length > 0")
            .jwl-submit-task__description-title
              | {{$t('course.facilitatorDocuments')}}

            ul
              lms-course-document(:jwl-file="task.facilitatorResources")

          .jwl-submit-task__documents(v-if="task.studentResources && task.studentResources.length > 0")
            .jwl-submit-task__description-title
              | {{$t('course.studentDocuments')}}

            ul
              lms-course-document(:jwl-file="task.studentResources")

          .jwl-submit-task__documents(v-if="work && work.publishedAt && task.sampleSolution && task.sampleSolution.length > 0")
            .jwl-submit-task__description-title
              | {{$t('course.sampleSolution')}}

            ul
              lms-course-document(:jwl-file="task.sampleSolution")

        form.jwl-submit-task__submit-container(v-if="!work.publishedAt" @submit.prevent="submitForm" ref="workSubmitForm")
          .jwl-submit-task__task-headline
            | {{$t('task.yourText')}}
          label.jwl-submit-task__form-label(for="work-subject")
            | {{$t('task.subjectPlaceholder')}}
          input#work-subject.jwl-submit-task__form-field(v-model="work.subject" :placeholder="$t('task.subjectPlaceholder')" type="text" name="workSubject")

          label.jwl-submit-task__form-label(for="work-text")
            | {{$t('task.textPlaceholder')}}
          ckeditor#work-text(v-model="work.text" :editor="editor" :config="editorConfig" :placeholder="$t('task.textPlaceholder')")

          input.jwl-submit-task__submit-button(:value="$t('task.submit')" type="submit" v-if="submitStatus !== 1")

          .jwl-submit-task__submitting(v-if="submitStatus === 1")
            | {{$t('syncStatus.submitting')}}

          .jwl-submit-task__submitted(v-if="submitStatus === 2")
            | {{$t('syncStatus.submitted')}}

          .jwl-submit-status__submit-error(v-if="submitStatus === -1")
            | {{error.trans ? $t(error.message) : error.message}}

        // If it's a new work, it does not have an ID and cannot be published
        .jwl-submit-task__task-container(v-if="work.text && work.text.length > 18 && (publishStatus === 2 || (!work.publishedAt && work.id))")
          | {{$t('task.publishable')}}
          .jwl-submit-task__submit-button(@click="publishForm()" v-if="publishStatus === 0")
            | {{$t('task.publishNow')}}

          .jwl-submit-task__submitting(v-if="publishStatus === 1")
            | {{$t('syncStatus.submitting')}}

          .jwl-submit-task__submitted(v-if="publishStatus === 2")
            | {{$t('syncStatus.submitted')}}

          .jwl-submit-status__submit-error(v-if="publishStatus === -1")
            | {{error.trans ? $t(error.message) : error.message}}

        .jwl-submit-task__task-container(v-if="work.publishedAt && work.id")
          .jwl-submit-task__task-headline
            | {{$t('task.yourText')}}

          .jwl-submit-task__task-title(v-if="work.subject" dir="auto")
            | {{work.subject}}

          .jwl-submit-task__task-copy(v-if="work.text" v-html="work.text" dir="auto")

          .jwl-submit-task__task-meta(v-if="work.publishedAt")
            | {{$t('gradebook.publishedAt', { date: localizedDate(work.publishedAt) })}}

        .jwl-submit-task__grade-container(v-if="work.id && work.publishedAt && !work.grade")
          .jwl-submit-task__grade-view.jwl-submit-task__grade-view--ungraded
            | {{$t('task.waitForGrade')}}

        .jwl-submit-task__grade-container(v-if="work.grade")
          .jwl-submit-task__grade-view(:class="gradeSubmissionClass")
            .jwl-submit-task__grade-view-headline
              | {{$tc('gradebook.pointsOutOf', task.maxScore, { achieved: work.grade.score, total: task.maxScore })}}
            .jwl-submit-task__grade-view-comment(dir="auto")
              | {{work.grade.comment}}
            .jwl-submit-task__grade-view-facilitator
              common-profile-image.jwl-submit-task__grade-view-facilitator-profile(:image-url="work.grade.facilitatorProfile")
              .jwl-submit-task__grade-view-facilitator-name
                | {{work.grade.name}}
              .jwl-submit-task__grade-view-facilitator-date
                | {{$t('gradebook.assessedAt', { date: localizedDate(work.grade.date) })}}

</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CommonError from 'common-modules/src/components/CommonError.vue';
import CommonProfileImage from 'common-modules/src/components/CommonProfileImage.vue';
import jwlTaskMeta from '../components/jwlTaskMeta.vue';
import BackBar from '../components/BackBar.vue';
import LmsCourseDocument from '@/components/LmsCourseDocument.vue';

const IconArrowLeft = () => import('common-modules/src/assets/fa-icons/solid/arrow-left.svg');
const IconArrowRight = () => import('common-modules/src/assets/fa-icons/solid/arrow-right.svg');
const IconFileAltDuotone = () => import('common-modules/src/assets/fa-icons/duotone/file-alt.svg');
const IconFileAltRegular = () => import('common-modules/src/assets/fa-icons/regular/file-alt.svg');

const STATUS_NOTHING = 0;
const STATUS_SUBMITTING = 1;
const STATUS_SUCCESS = 2;
const STATUS_ERROR = -1;

export default {
  components: {
    BackBar,
    IconArrowLeft,
    IconArrowRight,
    IconFileAltDuotone,
    IconFileAltRegular,
    jwlTaskMeta,
    CommonError,
    LmsCourseDocument,
    CommonProfileImage,
  },
  data () {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
        placeholder: this.$t('task.textPlaceholder'),
      },
      loaded: false,
      task: null,
      error: null,
      work: {
        id: null,
        subject: '',
        text: '',
        publishedAt: null,
        grade: null,
      },
      submitStatus: STATUS_NOTHING,
      publishStatus: STATUS_NOTHING,
    };
  },
  methods: {
    fetchData () {
      this.loaded = false;
      this.$store.dispatch('getData', `task/${this.$route.params.task}`)
        .then((data) => {
          this.task = data.task;
          if (data.work) {
            Object.assign(this.work, data.work);
          }
          this.loaded = true;
        })
        .catch((e) => {
          this.error = e;
        });
    },
    submitForm () {
      if (this.submitStatus !== STATUS_SUBMITTING && this.publishStatus === STATUS_NOTHING) {
        this.submitStatus = STATUS_SUBMITTING;
        this.error = null;
        const FD = new FormData(this.$refs.workSubmitForm);
        FD.append('workText', this.work.text);

        this.$store.dispatch('postData', {
          url: `task/${this.$route.params.task}`,
          formData: FD,
        }).then((data) => {
          Object.assign(this.work, data);
          this.submitStatus = STATUS_SUCCESS;

          setTimeout(() => {
            this.submitStatus = STATUS_NOTHING;
          }, 2500);
        }).catch((e) => {
          this.error = e;
          this.submitStatus = STATUS_ERROR;
        });
      }
    },
    publishForm () {
      if (this.submitStatus !== STATUS_SUBMITTING && this.publishStatus !== STATUS_SUBMITTING) {
        this.publishStatus = STATUS_SUBMITTING;
        this.$store.dispatch('publishTask', this.$route.params.task)
          .then((data) => {
            Object.assign(this.work, data);
            this.publishStatus = STATUS_SUCCESS;
            setTimeout(() => {
              this.publishStatus = STATUS_NOTHING;
            }, 2500);
          })
          .catch((e) => {
            this.error = e;
            this.publishStatus = STATUS_ERROR;
          });
      }
    },
    localizedDate (dateString) {
      const date = new Date(dateString);
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    },
  },
  computed: {
    gradeThemeClass () {
      return `jwl-submit-task--${this.task.programmeType}`;
    },
    gradeSubmissionClass () {
      const submitted = this.work;
      const baseClass = 'jwl-submit-task__grade-view';

      if (submitted.id) {
        if (submitted.grade) {
          if (submitted.grade.date) {
            if (this.task.maxScore > 0 && submitted.grade.score === 0) {
              return `${baseClass}--0-grade`;
            }
            return `${baseClass}--grade`;
          }
          return `${baseClass}--unpublished`;
        }
        return `${baseClass}--ungraded`;
      }
      return `${baseClass}--nothing`;
    },
  },
  watch: {
    $route () {
      this.fetchData();
    },
  },
  mounted () {
    this.fetchData();
  },
};
</script>

<style lang="sass">
@import "../styles/jwlSubmitTask"
</style>
